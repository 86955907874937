$(document).ready(function () {
  // iframe always show
  $("nav").show();
  $(".web-form-header").show();
  $(".page-breadcrumbs").show();
  $(".web-form")
	.css("border", "1px solid var(--dark-border-color)")
	.css("border-top", "none")
	.css("padding", "1.25rem 2rem 2rem");

  $(".avatar").click(() => {
	setTimeout(function () {
		// on click of avatar replace logout button href after 300ms.
		$('a.dropdown-item[href="/?cmd=web_logout"]')[0].href = "/web_logout";
	}, 300);
  });
});
